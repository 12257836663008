@import './variables.scss';

footer {
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 350;
  background-color: $dark;
  color: $white;
  display: flex;
  justify-content: center;
  div:first-child {
    padding: 20px 0px;
    width: 100%;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1300px ) {
      padding: 20px;
    }
    @media screen and (max-width: $sm-screen ) {
      flex-direction: column;
      padding: 20px;
    }
    .logo {
      width: 10%;
      padding: 0px;
      img {
        width: 70px;
        cursor: pointer;
      }
    }
    .social-icons {
      width: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 20px;
        height: 30px;
      }
      @media screen and (max-width: $sm-screen ) {
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        img {
          width: 30px;
          margin-top: 20px;
          margin-left: 20px;
        }
      }
    }
    .address {
      width: 30%;
      @media screen and (max-width: $sm-screen ) {
        width: 100%;
        margin: 20px 0px;
      }
      p {
        span {
          font-size: 14px;
          line-height: 1.5em;
        }
        text-align: right;
        font-size: 11px;
      }
    }
    .links {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: $sm-screen ) {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        padding: 0px 0px;
      }
      p {
        font-size: 11px;
        color: $white;
        margin: 10px;
        &:hover {
          color: $secondary;
        }
        @media screen and (max-width: $sm-screen ) {
          font-size: 14px;
          padding: 5px 0px;
          margin: 0px;
        }
      }
    }
  }
}

