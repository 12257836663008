@import './variables.scss';

.legal-notice-container {
  .back-arrow {
    height: 30px;
    font-family: "VistaSansRegular";
    display: flex;
    align-items: center;
    color: $secondary;
    .arrow {
      width: 20px;
      margin-right: 8px;
    }
  }
  .title {
    color: $secondary;
    font-size: 1.8em;
  }
  h4 {
    margin: 20px 0px 0px 0px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px;
  a {
    color: $secondary;
    display: inline;
  }
  ul {
    padding-left: 20px;
    li {
      margin: 10px 0px;
    }
  }
  @media screen and (max-width: $sm-screen) {
    margin: 0px 0px 20px 0px;;
  }
  section {
    width: 50%;
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
  }
}