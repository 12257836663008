@import './transition.scss';
@import './variables.scss';

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  font-family: "VistaSansRegular", helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overscroll-behavior: none;
  cursor: auto;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.regular {
  font-family: "VistaSansRegular";
}

.bold {
  font-family: "VistaSansBold";
  letter-spacing: .72px;
}

.text-center {
  text-align: center;
}

.black {
  font-family: "VistaSansBlack";
  letter-spacing: .72px;
}

h2 {
  font-size: 21px;
  font-weight: normal;
}

p {
  font-size: 16px
}

.text-small {
  font-size: 11px
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0px;
  cursor: pointer;
}


.hide-desktop {
  display: none;
  @media screen and (max-width: $sm-screen) {
    display: block;
  }
}

.hide-mobile {
  display: block;
  @media screen and (max-width: $sm-screen) {
    display: none;
  }
}

#root {
  scrollbar-color: #D8D8D8  #FFFFFF !important;
  scrollbar-width: 5px !important;
  @media screen and (max-width: $sm-screen) {
    height: auto;
    scrollbar-color: #D8D8D8  transparent !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent ; 
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #D8D8D8 ; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary; 
}

.d-none {
  display: 'none';
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-gray {
  color: $dark;
  opacity: 0.3;
}

.disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.not-active {
  cursor: not-allowed;
  pointer-events: none;
} 

.btn {
  padding: 10px 15px;
  font-family: "VistaSansBold";
  border-radius: 7px;
  border: 0px;
  font-size: 17px;
  outline: none;
  letter-spacing: .72px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &.xs {
    padding: 5px 8px;
    font-size: 14px;
  }
  &.white {
    background-color: $white;
    color: $dark;
    &:hover {
      background-color: $secondary;
    }
  }
  &.primary {
    background-color: $secondary;
    color: $white;
    &:hover {
      background-color: $dark;
      color: $white;
    }
  }
  &.dark {
    background-color: $dark;
    color: $white;
    &:hover {
      background-color: $secondary;
    }
  }
}

.select-container {
  position: absolute;
  margin-top: 8px;
  width: 260px;
  left: 30px;
  @media screen and (max-width: $sm-screen) {
    position: relative;
    margin-top: 0px;
    left: 0px;
    width: 100%;
  }
}

.full-height {
  min-height: 100vh;
  overflow: hidden;
  &.iframe {
    min-height: 830px;
    height: 100%;
  }
  @media screen and (max-width: $sm-screen) {
    height: auto;
    min-height: 100vh;
  }
}

.main-container {
  position: relative;
  // overflow: hidden;
  width: 100%;
  // height: auto;
  display: flex;
  // overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // &.full-height {
  //   height: 100vh;
  //   overflow: hidden;
  // }
  @media screen and (max-width: $sm-screen) {
    min-height: 100vh;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.page {
  position: relative;
  padding: 0px;
  width: 100%;
  font-weight: 20;
  font-size: 1em;

  @media screen and (max-width: $sm-screen) {
    background-color: rgba(#EDF1F0, 0.3);
  }
  
  &.legal {
    padding: 20px;
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
  }
}

.tooltip {	
  position: fixed;			
  text-align: left;			
  padding: 8px;				
  background: $white;
  z-index: 1000;	
  border: 0px;
  font-size: 12px;
  pointer-events: none;
  width: 250px;
  box-shadow: 0px 0px 2px 2px rgba(0,0,0, 0.1);
  p {
    &.text-center {
      width: 100%;
      text-align: center;
    }
  }
  .name {
    margin: 0px 0px 5px 0px;
  }
  .row {
  display: flex;
  justify-content: space-between;
    p {
      width: 25%;
      margin: 4px 0px 0px 0px;
      font-size: 12px;
      text-align: right;
      &.candidat {
        width: 50%;
        text-align: left;
      }
    }
  }		
};


