@import './variables.scss';

.participation {
  z-index: 309;
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  text-align: left;
  margin-right: 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  width: 160px;
  max-width: 160px;
  &.office {
    position: relative;
    top: 0px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: $sm-screen) {
    position: relative;
    right: none;
    margin-top: 0px;
    top: 0px;
    margin-right: 0px;
  }
  .label {
    font-size: 11px;
    margin: 0px;
  }
  .pariciptation-range {
    width: 200px;
    max-width: 200px;
    &.office {
      width: 100px;
      max-width: 100px;
    }
    .range-bar {
      margin-bottom: 5px;
      background-color: $primary;
    }
  }
  h5 {
    margin: 0px 0px 5px 20px;
    color: $primary;
    text-align: right;
  }
  h3 {
    margin: 0px 0px 5px 20px;
    color: $primary;
    text-align: right;
    &.office {
      font-size: 16px;
    }
  }
  p {
    margin: 0px;
    text-align: right;
  }
}