$secondary: #FF8C7E;
$secondary-dark: #d8796c;
$primary: #1D5993;
$white: #FFFFFF;
$dark: #000000;
$light-grey: #F0F0F0;

$lg-screen:1200px;
$md-screen: 1025px;
$sm-screen: 768px;
$xs-screen: 576px;

@font-face {
	font-family: "VistaSansRegular";
	src: url("../assets/fonts/VistaSansOT-Reg.otf") format("truetype");
}

@font-face {
	font-family: "VistaSansBold";
	src: url("../assets/fonts/VistaSansOT-Bold.otf") format("truetype");
}

@font-face {
	font-family: "VistaSansBlack";
	src: url("../assets/fonts/VistaSansOT-Black.otf") format("truetype");
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}