@import '../../../styles/variables.scss';

.container {
  margin: 30px 0px;
  @media screen and (max-width: $sm-screen) {
    min-height: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column-reverse;
  }
  h3 {
    font-size: 16px;
    @media screen and (max-width: $sm-screen) {
      margin: 0px;
    }
  }
  .btn {
    margin-bottom: 10px;
  }
  h2 {
    cursor: pointer;
    pointer-events: all;
    margin: 0px;
    &:hover {
      color: $secondary;
      opacity: 1;
    }
    svg {
      transform: translateY(4px);
      opacity: 0;
    }
    &.not-active {
      opacity: 0.3;
      svg {
        opacity: 1;
      }
      &:hover {
        color: $secondary;
        opacity: 1;
      }
    }
    @media screen and (max-width: $sm-screen) {
      display: none;
      &.not-active {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        font-size: 20px;
        color: $primary;
        opacity: 1;
        &:hover {
          color: $primary;
          opacity: 1;
        }
        svg {
          transform: translateY(0px) scale(1.14);
          margin-right: 10px;
        }
      }
    }
  }
}