@import './variables.scss';

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8% 0%;
  @media screen and (max-width: $md-screen) {
    padding: 30px 0%;
  }
  @media screen and (max-width: $sm-screen) {
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .home-header-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    @media screen and (max-width: $md-screen) {
      width: 95%;
    }
    @media screen and (max-width: $sm-screen) {
      flex-direction: column;
      width: 100%;
    }
    button {
      text-align: left;
    }
    .illu {
      width: 45%;
      height: auto;
      max-height: 360px;
      transform: translate(-40px, -40px) scale(1.2);
      @media screen and (max-width: $sm-screen) {
        transform: translateY(0px) scale(1);
        width: 100%;
        height: 320px;
      }
      @media screen and (max-width: $xs-screen) {
        height: 260px;
      }
    }
  }
  .links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 30px;
    @media screen and (max-width: $md-screen) {
      width: 95%;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 0px;
      margin-bottom: 30px;
      width: 100%;
    }
    .link {
      width: calc(33.33% - 20px);
      margin-top: 30px;
      background-color: $dark;
      border-radius: 5px;
      min-height: 120px;
      @include transition;
      @media screen and (max-width: $sm-screen) {
        width: calc(50% - 10px);
        min-height: 90px;
      }
      @media screen and (max-width: 380px) {
        width: 100%;
        min-height: 90px;
      }
      &.is-hidden {
        background-color: #acacac;
        pointer-events: none;
      }
      h2 {
        margin: 25px 30px 0px 30px;
        color: $white;
        @media screen and (max-width: $sm-screen) {
          font-size: 18px;
          margin: 15px 15px 0px 15px;
        }
      }
      p {
        margin: 0px 30px 30px;
        color: $white;
        font-size: 16px;
        @media screen and (max-width: $sm-screen) {
          margin: 0px 15px 15px;
          font-size: 14px;
        }
      }
      &:hover {
        background-color: $secondary;
        color: $dark;
      }
    }
  }
}

// .open-data {
//   position: relative;
//   right: 5px;
//   z-index:2;
//   width: 25%;
//   height: 80%;
//   pointer-events: none;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-end;
//   text-align: right;
//   border-left: 2px solid $secondary;
//   @media screen and (max-width: $sm-screen) {
//     align-items: flex-start;
//   }
//   h2 {
//     padding-left: 20px;
//     @media screen and (max-width: $sm-screen) {
//       padding-left: 0px;
//       text-align: left;
//     }
//   }
//   button {
//     pointer-events: all;
//   }
//   @media screen and (max-width: $sm-screen) {
//     margin-bottom: 40px;
//     width: 100%;
//     position: relative;
//     padding-top: 0px;
//     right: 0px;
//     border-left: 0px solid $secondary;
//     border-top: 2px solid $secondary;
//   }
//   img {
//     margin-top: -50px;
//     max-height: 200px;
//     min-width: 20%;
//     height: auto;
//     @media screen and (max-width: $sm-screen) {
//       margin-top: 20px;
//       // width: 100%;
//       // margin-top: 20px;
//       // margin-right: 0px;
//     }
//   }
//   h1 {
//     margin-bottom: 0px;
//   }
// }
