@import './variables.scss';

// .page-header {
//   position: fixed;
//   top: 0;
//   display: flex;
//   z-index: 100;
//   width: 29%;
//   min-width: 330px;
//   padding: 10px 20px 20px 40px;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   background-color: #EDF1F0;
//   border-bottom-right-radius: 20px;
//   @include transition;
//   @media screen and (max-width: $md-screen) {
//     width: 24%;
//   }
//   @media screen and (max-width: $sm-screen) {
//     padding: 20px 30px;
//     border-bottom-right-radius: 0px;
//     width: 100%;
//   }
//   .back-arrow {
//     height: 30px;
//     font-family: "VistaSansRegular";
//     display: flex;
//     align-items: center;
//     color: $secondary;
//     .arrow {
//       width: 20px;
//       margin-right: 8px;
//     }
//   }
//   .title-container {
//     .title {
//       max-width: 200px;
//       display: flex;
//       justify-content: flex-start;
//       align-items: flex-end;
//       @media screen and (max-width: $sm-screen) {
//         max-width: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: flex-start;
//       }
//     }
//     h3 {
//       margin-top: 0px;
//       margin-left: 10px;
//       margin-bottom: 2px;
//       z-index: 100;
//       @media screen and (max-width: $sm-screen) {
//         margin-left: 0px;
//       }
//     }
//     h1 {
//       margin: 0px;
//       z-index: 100;
//       @media screen and (max-width: $sm-screen) {
//         font-size: 28px;
//       }
//     }
//   }
//   .text-toggle {
//     position: absolute;
//     top: 70px;
//     right: 20px;
//     img {
//       width: 30px;
//     }
//   }
//   .text {
//     @include transition;
//     padding-right: 20px;
//   } 
//   &.live {
//     h1 {
//       font-size: 26px;
//     }
//     .text-toggle {
//       top: 94px;
//     }
//   }
// }

.container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  #map {
    @media screen and (max-width: $sm-screen) {
      position: absolute;
      width: 100%;
      height: calc(100vh - 0px);
      width: 100%;
      transform: translateY(-180px);
      margin-top: 0px;
      justify-content: center;
    }
  }
  &.loader {
    height: 100%;
    @media screen and (max-width: $sm-screen) {
      justify-content: space-between;
      align-items: center;
      min-height: 60vh;
      height: auto;
    }
  }
  @media screen and (max-width: $sm-screen) {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
  .col-candidats {
    padding-left: 40px;
    z-index: 3;
    width: 30%;
    margin-top: 120px;
    margin-bottom: 100px;
    @media screen and (max-width: $md-screen) {
      width: 35%;
    }
    @media screen and (max-width: $sm-screen) {
      margin-top: 110px;
      padding: 30px;
      width: 100%;
    }
    &.direct {
      @media screen and (max-width: $sm-screen) {
        margin-top: 140px;
        padding: 30px;
        width: 100%;
      }
    }
  }
  .col-dataviz {
    position: relative;
    padding: 20px;
    width: calc(75% - 40px);
    display: flex;
    justify-content: center;
    @media screen and (max-width: $md-screen) {
      width: calc(100% - 330px);
    }
    @media screen and (max-width: $sm-screen) {
      width: 100%;
    }
    #map, #donut {
      svg {
        margin-top: 0px;
        margin-left: 0px;
        @media screen and (max-width: $md-screen) {
          margin-top: 120px;
          margin-left: 40px;
        }
      }
    }
    #map {
      width: 100%;
      height: 90%;
      display: flex;
      align-items: center;
      overflow: visible;
      z-index: 2;
      @media screen and (min-height: 1366px) {
        max-height: 600px;
      }
      @media screen and (max-height: 680px) {
        max-height: 450px;
      }
      @media screen and (max-width: $md-screen) {
        width: 100%;
        margin-top: 65px;
        max-height: 480px;
        max-width: 480px;
        transform: scale(0.85);
        justify-content: center;
      }
      svg {
        overflow: visible;
        z-index: 106;
        @media screen and (min-height: 1366px) {
          max-height: 600px;
          transform: translate(0px, 300px);
        }
      }
    }
    #donut {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      @media screen and (max-width: $md-screen) {
        margin-top: 65px;
        max-height: 480px;
        max-width: 480px;
      }
      path {
        pointer-events: all;
      }
      svg {
        overflow: visible;
        transition: all ease-in-out;
        @media screen and (min-height: 1366px) {
          transform: translate(0px, 300px);
        }
        
      }
      text {
        text-shadow: 0px 0px 4px rgb(255, 255, 255);
      }
    }
  }
}

.center-list {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (max-width: $sm-screen) {
    height: auto;
    max-height: none;
    padding-right: 0px;
    margin-bottom: 40px;
  }
  h2 {
    margin-top: 0px;
  }
  .center {
    margin-bottom: 60px;
  }
}

.progress-container {
  position: absolute;
  bottom: 40px;
  width: 25%;
  @media screen and (max-width: $md-screen) {
    width: 35%;
  }
  @media screen and (max-width: $sm-screen) {
    position: relative;
    margin: 10px 0px;
    bottom: 0px;
    width: 100%;
  }
  p {
    font-size: 14px;
  }
  .progress-bar {
    width: 100%;
    background-color: rgb(209, 209, 209);
    border-radius: 8px;
    height: 10px;
    .progress-value {
      height: 100%;
      background-color: $dark;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      transition: all 0.5s ease-in-out;
      &.progress-end {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-radius: 8px;
      }
    }
  }
}

.btn-share {
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 5px;
  margin-left: 20px;
  border-radius: 0px;
  background-color: $secondary;
  border-radius: 3px;
  font-size: 12px;
  font-family: "VistaSansRegular";
  color: $white;
  &:hover {
    background-color: $secondary;
  }
  img {
    width: 20px;
    margin-right: 5px;
  }
  @media screen and (max-width: $sm-screen) {
    display: none;
  }
}

.share-modale {
  cursor: auto;
  position: absolute;
  width: 29%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EDF1F0;
  min-height: 150px;
  margin-top: 150px;
  z-index:400;
  border-color: #EDF1F0;;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-style: solid;
  border-width: 1px;
  transition: all 0.5s ease-in-out;
  @media screen and (max-width: $md-screen) {
    width: 35%;
  }
  &.not-active {
    transform: translate(-150% , 0);
  }
  textarea {
    width: 90%;
    max-width: 90%;
    height: 100px;
    font-size:12px;
    border-color: #E7E7E7;
    color: #A2A2A2;
  }
  .bar-share {
    width: 90%;
    .bar {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
  h2 {
    text-align: left;
    width: 90%;
  }
  p {
    width: 90%;
    text-align: center;
  }
  .btn-container {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    button {
      margin:  20px 0px 20px 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-family: "VistaSansRegular";
    }
  }
}
