@import './variables.scss';

.list-container {
  margin-top: 100px;
  width: 60%;
  margin-left: 15%;
  @media screen and (max-width: $md-screen) {
    width: 80%;
  }
  @media screen and (max-width: $sm-screen) {
    width: 88%;
    margin-left: 0px;
    margin: 16px 20px;
    position: absolute;
    height: 85%;
    h1 {
      max-width: 200px;
      margin: 0px;
      margin-bottom: 20px;
    }
  }
  .list {
    height: 80vh;
    padding-right: 10px;
    overflow: hidden;
    overflow-y: auto;
    @media screen and (max-width: $sm-screen) {
      overflow-y: scroll;
      position: relative;
      height: 100%;
    }
    .list-item-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      font-size: 18px;
      .title {
        display: flex;
        justify-content: space-between;
        .name {
          font-size: 23px;
        }
        @media screen and (max-width: $sm-screen) {
          flex-direction: column;
          font-size: 16px;
        }
        @media screen and (max-width: $sm-screen) {
          .name {
            font-family: "VistaSansBold";
            margin-bottom: 10px;
          }
        }
        p {
          margin: 0;
          .label {
            font-size: 11px;
          }
          @media screen and (max-width: $sm-screen) {
            margin-top: 5px;
          }
        }
      }
      .result-row {
        width: 70%;
        margin: 12px 0px;
        display: flex;
        @media screen and (max-width: $sm-screen) {
          display: none;
        }
        p {
          margin: 0px;
          font-size: 16px;
          width: 25%;
          text-align: right;
          word-break: break-word;
          &.name {
            width: 50%;
            text-align: left;
            color: $dark;
          }
        }
      }
    }
  }
}