@import '../../../styles/variables.scss';

.container {
  position: absolute;
  top: calc(50vh  - 52px);
  right: 0px;
  z-index: 310;
  @media screen and (max-width: $sm-screen) {
    background-color: $white;
    position: relative;
    top: 0px;
    border-radius: 36px;
    border: 1px solid rgba($dark, 0.2);
    display: flex;
    flex-direction: row-reverse;
  }
  button {
    width: 52px;
    height: 52px;
    background-color: $light-grey;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 30px;
    &:first-child {
      img {
        width: 28px;
      }
    }
    p {
      @include transition();
      position: absolute;
      font-family: "VistaSansRegular";
      pointer-events: none;
      width: 100px;
      right: 30px;
      text-align: right;
      opacity: 0;
    }
    &:hover {
      p {
        right: 70px;
        opacity: 0.4;
      }
    }
    &.active {
      background-color: $dark;
    }
    @media screen and (max-width: $sm-screen) {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      background-color: transparent;
      &:first-child {
        img {
          width: 20px;
        }
      }
      &:last-child {
        img {
          width: 18px;
        }
      }
      &.active {
        background-color: $dark;
      }
      p {
        display: none;
      }
    }
  }
};
