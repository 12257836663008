@import '../../../../styles/variables.scss';

.container {
  position: fixed;
  width: 100%;
  left: 20px;
  bottom: 20px;
  .progress {
    width: calc(100vw - 40px);
  }
  .nav {
    position: relative;
    z-index: 0;
    height: 36px;
    padding: 6px 12px;
    width: calc(100% - 38px);
    background-color: rgba(0, 0, 0, 0.05);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .back {
      position: absolute;
      height: 25px;
      overflow: hidden;
    }
  }
  .result {
    background: $white;
    z-index: 200;
    box-shadow: 0px 10px 46px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: calc(100vw - 40px);
  }
}