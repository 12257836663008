@import '../../../styles/variables.scss';

.container {
  position: fixed;
  top: 0;
  display: flex;
  z-index: 320;
  width: 29%;
  min-width: 330px;
  padding: 10px 20px 20px 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #EDF1F0;
  border-bottom-right-radius: 20px;
  @include transition;
  @media screen and (max-width: $md-screen) {
    width: 24%;
  }
  @media screen and (max-width: $sm-screen) {
    padding: 15px 20px;
    background-color: $white;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .back {
    height: 30px;
    font-family: "VistaSansRegular";
    display: flex;
    align-items: center;
    color: $secondary;
    text-transform: uppercase;
    .home {
      width: 18px;
      margin-right: 6px;
      margin-bottom: 3px;
    }
  }
  .title-container {
    width: 100%;
    position: relative;
    .title {
      max-width: 200px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      @media screen and (max-width: $sm-screen) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    h3 {
      margin-top: 0px;
      margin-left: 10px;
      margin-bottom: 2px;
      z-index: 100;
      @media screen and (max-width: $sm-screen) {
        margin-left: 0px;
      }
    }
    h1 {
      margin: 0px;
      z-index: 100;
      @media screen and (max-width: $sm-screen) {
        font-size: 28px;
      }
    }
    .toggle {
      position: absolute;
      bottom: -5px;
      right: 0px;
      img {
        width: 30px;
      }
    }
  
  }
  .text {
    @include transition;
    padding-right: 20px;
    @media screen and (max-width: $sm-screen) {
      padding-right: 0px;
    }
  } 
  &.direct {
    h1 {
      font-size: 26px;
    }
  }
}