@import './transition.scss';
@import './variables.scss';

.select-bureau {
  position: absolute;
  bottom: 110px;
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  @media screen and (max-height: 680px) {
    bottom: 10px;
  }
  @media screen and (max-width: $md-screen) {
    bottom: -10px;
  }

  .btn-arrow {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $secondary;
    }
    img {
      margin: 0px 10px;
    }
    &.not-active {
      opacity: 0.2;
      pointer-events: none;
      cursor: none;
    }
  }
  .name-container {
    margin: 0px 20px;
    p {
      margin: 0px;
      text-align: center;
    }
    .radios-container {
      display: flex;
      justify-content: center;
      width: 100%;
      .radio {
        width: 20px;
        height: 20px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background-color: $white;
        border: 1px solid $dark;
        z-index: 320;
        .checked {
          opacity: 0;
          width: 10px;
          height: 10px;
          border-radius: 15px;
          background-color: $dark;
          transition: all 0.2s ease-in-out;
          &:hover {
            opacity: 0.2;
          }
          &.active {
            opacity: 1;
            background-color: $secondary;
          }
        }
      }
    }
  }
}