.slide-in-fade {
  animation-duration: 1s;
  animation-name: slideinFade;
  animation-fill-mode: forwards;
  transform: translate( 0 , 0 );
  opacity: 1;
}

@keyframes slideinFade {
  from {
    opacity: 0;
    transform: translate( 0 , 0 );
  }
  to {
    opacity: 1;
    transform: translate( 0 , 0 );
  }
}

.slide-in-right {
  animation-duration: 1s;
  animation-name: slideinRight;
  animation-fill-mode: forwards;
  transform: translate( 0 , 0 );
  opacity: 1;
}

@keyframes slideinRight {
  from {
    opacity: 0;
    transform: translate( 100px , 0 );
  }
  to {
    opacity: 1;
    transform: translate( 0 , 0 );
  }
}

.slide-in-left {
  animation-duration: 1s;
  animation-name: slideinLeft;
  animation-fill-mode: forwards;
  transform: translate( 0 , 0 );
  opacity: 1;
}

@keyframes slideinLeft {
  from {
    opacity: 0;
    transform: translate( -100px , 0 );
  }
  to {
    opacity: 1;
    transform: translate( 0 , 0 );
  }
}

@mixin slide-in-left($time) {
  animation-duration: $time;
  animation-name: slideinLeft;
  animation-fill-mode: forwards;
  transform: translate( 0 , 0 );
  opacity: 1;
}

@mixin slide-in-nav($time) {
  animation-duration: $time;
  animation-name: slideinTop;
  animation-fill-mode: forwards;
  transform: translate( 0 , 0 );
  opacity: 1;
  @media screen and (max-width: $sm-screen) {
    animation-name: notAnimated;
  }
}

@keyframes slideinTop {
  from {
    opacity: 0;
    transform: translate( 0 , -50px );
  }
  to {
    opacity: 1;
    transform: translate( 0 , 0 );
  }
}

@keyframes notAnimated {
  from {
    opacity: 1;
    transform: translate( 0 , 0 );
  }
  to {
    opacity: 1;
    transform: translate( 0 , 0 );
  }
}

.anim-enter-donut {
  animation-duration: 0.75s;
  animation-name: slideinRotate;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes slideinRotate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
