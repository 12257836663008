@import '../../../styles/variables.scss';

.legend {
  position: relative;
  margin-bottom: 10px;
  right: 0;
  width: 100%;
  @media screen and (max-width: $sm-screen) {
    width: 100%;
  }
  h5 {
    color: $primary;
    margin: 5px 0px;
    text-align: right;
    @media screen and (max-width: $sm-screen) {
      text-align: left;
    }
  }
  .legend-row {
    display: flex;
    align-items: center;
    width: 100%;
    &.text {
      margin-top: 5px;
      font-size: 14px; 
      justify-content: space-between;
    }
    .legend-color {
      background-color: $primary;
      width: calc(100% / 5);
      height: 20px;
    }
  }
}