@import "./variables.scss";

.cookies-bar {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: $dark;
  color: $secondary;
  transition: all 0.5s ease-in-out;
  p {
    margin: 20px 20px 0px 20px ;
  }
  &.not-active {
    transform: translate(0px , 300px);
  };
}