@import './transition.scss';
@import './variables.scss';

.bg-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  .img-container {
    max-width: 1300px;
    z-index: -1;
    img {
      top:0;
      float: right;
      // overflow-y: hidden;
      @include transition;
      transform-origin: 90% -22%;
      margin-right: 0px;
      @media screen and (min-width: 1024px ) and (max-height: 768px)  {
        margin-top: 2%;
        margin-right: 4%;
      }
      @media screen and (max-height: 850px) {
        margin-top: 0%;
      }
      @media screen and (min-height: 850px) {
        margin-top: 3%;
      }
      @media screen and (min-height: 900px) {
        margin-top: 6%;
        margin-right: 1%;
      }
      @media screen and (min-height: 1000px) {
        margin-top: 8%;
        margin-right: 0px;
      }
      @media screen and (min-height: 1200px) {
        margin-top: 23%;
        margin-right: 4%;
      }
      @media screen and (min-width: $sm-screen) and (max-height: 768px) {
        margin-top: 0%;
        margin-right: 0%;
      }
      &.init-scale {
        transform: scale(0.7) translate( 5% , -10%);
        opacity: 0.3;
        @media screen and (max-width: $sm-screen) {
          opacity: 0.2;
        }
      }
      &.legal-scale {
        transform: scale(0.7) translate( -13% , -10%);
        opacity: 0.3;
        @media screen and (max-width: $sm-screen) {
          opacity: 0.2;
        }
      }
      &.scale-city {
        transform: scale(0.6) translate( 0% , 0%);
        opacity: 0.3;
        @media screen and (max-width: $sm-screen) {
          opacity: 0.2;
        }
      }
      &.scale-center {
        transform: scale(0.5) translate( -8% , 13%)  ;
        opacity: 0.3;
      }
      &.scale-bureau {
        transform: scale(0.35) translate( -25% , 48%)  ;
        // filter: blur(4px);
        opacity: 0;
      }
    }
  }
}
