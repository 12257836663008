@import '../../../styles/variables.scss';

.container {
  margin-top: 110px;
  width: 100%;
  .header {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 300;
    .date {
      width: calc(100% - 110px);
    }
  }
  .list {
    padding: 0px 20px;
    overflow: auto;
    height: calc(100vh - 188px);
  }
}