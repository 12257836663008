@import '../../../../styles/variables.scss';

.container {
  h4 {
    font-family: "VistaSansRegular";
    font-size: 19px;
    color: $dark;
    margin: 25px 20px 15px 0px;
  }
  .input {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    input {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #C0C0C0;
      border-radius: 3px;
      padding: 10px;
      font-family: "VistaSansRegular";
      &:focus {
        outline: none;
      }
    }
    img {
      position: absolute;
      right: 10px;
      pointer-events: none;
      top: 10px;
    }
  }
  .option {
    background: $dark;
    box-shadow: 0px 10px 46px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: "VistaSansRegular";
    color: $white;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0px;
    }
    img {
      max-width: 20px;
      transform: rotate(180deg);
    }
  }

}