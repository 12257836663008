@import './variables.scss';
@import './transition.scss';

header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.home-header {
  margin-top: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (max-width: $sm-screen) {
    margin-top: 0px;
  }
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 5px;
    @media screen and (max-width: $sm-screen) {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
  span {
    font-size: 21px;
    @media screen and (max-width: $sm-screen) {
      font-size: 14px;
    }
  }
  img {
    width: 49px;
    margin-right:20px;
    @media screen and (max-width: $sm-screen) {
      width: 29px;
      margin-right: 15px;
    }
  }
  .btn-nav-mobile {
    display: none;
    @media screen and (max-width: $sm-screen) {
      display: block;
      padding: 0px;
      img {
        margin-right: 20px;
        width: 22px;
      }
    }
  }
}

.bar {
  margin-top: 40px;
  margin-bottom: 10px;
  width: 56px;
  border-bottom: 3px solid $dark;
  @media screen and (max-width: $sm-screen) {
    margin-top: 20px;
    width: 37px;
  }
}
a {
  color: $dark;
  display: block;
  width: auto;
}