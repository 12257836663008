@import '../../../styles/variables.scss';

.container {
  margin-top: 40px;
  z-index: 310;
  width: 100%;
  @media screen and (max-width: $sm-screen) {
    margin-top: 0px;
  }
  .location {
    padding: 8px 15px 6px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 19px;
      max-width: 230px;
      margin: 0px;
    }
    button {
      display: flex;
      align-items: center;
      color: $secondary;
      font-family: "VistaSansRegular";
      img {
        margin-left: 6px;
      }
    }
  }
  .list {
    max-height: 48vh;
    width: 100%;
    padding-right: 10px;
    margin-top: 40px;
    overflow: hidden;
    overflow-y: auto;
    @media screen and (max-width: $md-screen) {
      max-height: 55vh;
    }
    @media screen and (max-width: $sm-screen) {
      height: auto;
      max-height: none;
      padding-right: 0px;
      margin-top: 0px;
    }
  }
}