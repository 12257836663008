@import '../../../styles/variables.scss';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 20px;
  margin-bottom: 20px;
  width: 100%;
  @media screen and (max-width: $sm-screen) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-right: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .bar-chart {
    width: 120px;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    @media screen and (max-width: $sm-screen) {
      width: 50%;
      min-width: 50%;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
    }
    .bar {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: $sm-screen) {
        background-color: rgba($secondary, 0.3);
        border-radius: 4px;
      }
    }
    .bar-legend {
      font-size: 16px;
      @media screen and (max-width: $sm-screen) {
        min-width: 60px;
        font-size: 15px;
        text-align: right;
      }
    }
  }
  .infos {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: $sm-screen) {
      width: 50%;
      margin-left: 15px;
    }
    .name {
      font-size: 16px;
      @media screen and (max-width: $sm-screen) {
        font-size: 15px;
      }
    }
  }
  .range-bar {
    height: 4px;
    background-color: $secondary;
    border-radius: 4px;
  }
}