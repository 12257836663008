@import '../../../../styles/variables.scss';

.container {
  @include transition;
  position: fixed;
  background-color: $white;
  top: 110px;
  height: calc(100vh - 110px);
  overflow: auto;
  width: 100%;
  z-index: 300;
  right: -100%;
  &.active {
    right: 0px;
  }
  .select {
    padding: 20px;
  }
  h2 {
    margin: 0px;
    padding: 0px 20px 10px 20px;
  }
  .result {
    padding: 0px 20px;
    color: rgba($dark, 0.4);
    font-size: 14px;
    .candidat {
      color: $dark;
      margin: 10px 0px;
      .bar {
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
        height: 4px;
        @media screen and (max-width: $sm-screen) {
          background-color: rgba($secondary, 0.3);
          border-radius: 4px;
        }
        .range-bar {
          @include transition;
          height: 4px;
          background-color: $secondary;
          border-radius: 4px;
        }
      }
    }
    .row {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0px;
        width: 20%;
        text-align: right;
        &.name {
          width: 60%;
          text-align: left;
        }
      } 
    }
    .participation {
      margin-top: 20px;
      .value {
        margin: 8px 0px;
        font-size: 16px;
        color: $dark;
        display: flex;
        justify-content: flex-end;
      }
      .bar {
        margin: 5px 0px 10px 0px;
        display: flex;
        justify-content: flex-end;
        height: 4px;
        @media screen and (max-width: $sm-screen) {
          background-color: rgba($primary, 0.3);
          border-radius: 4px;
        }
        .range-bar {
          @include transition;
          height: 4px;
          background-color: $primary;
          border-radius: 4px;
        }
      }
      .row {
        font-size: 16px;
        &:not(:first-child) {
          padding: 5px 0px;
          border-bottom: 1px solid #EDF1F0;
        }
      }
    }
  }
  .back {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-family: "VistaSansRegular";
      background-color: $secondary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      padding: 10px 16px;
      margin: 20px;
      border-radius: 3px;
      text-transform: uppercase;
      img {
        max-width: 18px;
        margin-right: 8px;
      }
    }
  }
}